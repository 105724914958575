import React from 'react';
import Scroll from '../common/Scroll';

const Introduction = () =>
  <section id="intro" className="wrapper style1 fullscreen fade-up">
    <div className="inner">
      <h1>Elizabeth Franklin</h1>
      <p>
        I am a full-time Umbraco Frontend Developer with experience in HTML, CSS, JavaScript, React, Razor, Bootstrap and more.
        <br />
        I am driven to succeed. I love learning new things and honing my skills as a developer.
      </p>
      <ul className="actions">
        <li>
          <Scroll type="id" element="one">
            <a href="#one" className="button">
              Projects
            </a>
          </Scroll>
          <a href="/generic" className="button">
            Resume
          </a>
        </li>
      </ul>
    </div>
  </section>


export default Introduction;
