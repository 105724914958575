import React from 'react';

import Capability from './Capability';

const CapabilityList = () => {
  const CAPABILITIES = [
    {
      heading: 'Umbraco Frontend Developer at GRYT Health',
      description: 'September 2021 - Present',
      iconClass: 'fa-briefcase',
    },
    {
      heading: 'Systems Analyst at Williamsfield Valley Veneer, Inc.',
      description: 'November 2017 - September 2021',
      iconClass: 'fa-briefcase',
    },
    {
      heading: 'Systems Analyst at Bruce & Merrilees Electric Co.',
      description: 'September 2010 - October 2017',
      iconClass: 'fa-briefcase',
    },
    {
      heading: 'Customer Relations Specialist at Office Team',
      description: 'Working as a temp-to-hire for Bruce & Merrilees Electric Co. May 2010 - September 2010',
      iconClass: 'fa-briefcase',
    },
    {
      heading: 'American Business and Technology University',
      description: 'Network Administration & Internet Security, A.A.S. 2017',
      iconClass: 'fa-graduation-cap',
    },
    {
      heading: 'ITT Technical Institute',
      description: 'Network Systems Administration, A.A.S. 2015-2016',
      iconClass: 'fa-graduation-cap',
    },
    {
      heading: 'Technical Skills',
      description: 'React, Redux, React-Router, Styled-Components, Redux Thunk, Redux Persist, JavaScript, HTML, CSS, Responsive Web Design, Git, GitHub, Umbraco CMS, Razor Markup, jQuery, Bootstrap, Sass, Microsoft Office, and advanced computer troubleshooting.',
      iconClass: 'fa-code',
    },
    {
      heading: 'Soft Skills',
      description: 'Works well with upper management and co-workers. Co-worker support and training experience. Flexible – willing to accept new challenges with an open mind.',
      iconClass: 'fa-users',
    },
    // {
    //   heading: 'Hobbies',
    //   description: 'When I\'m not coding and/or working on computers, I enjoy writing, playing video games, and watching movies with my husband and step-daughter.',
    //   iconClass: 'fa-gamepad',
    // },
  ]

  const [capabilities] = React.useState(CAPABILITIES);

  return (
    <>
      {capabilities.map((capability) => <Capability key={capability.heading} {...capability} />)}
    </>
  )
}

export default CapabilityList;
