import React from "react";

const ContactInformation = () =>
  <section>
    <ul className="contact">
      <li>
        <h3>Location</h3>
        <a href="https://goo.gl/maps/QgpM1iMEWxDXugku9" target="_blank">
          <div class="mapMarker"><i className="fa fa-map-marker"></i></div>
          <span class="mapText">
            Youngstown, Ohio
            <br />
            USA
          </span>
        </a>
      </li>
      <li>
        <h3>Email</h3>
        <a href="mailto:elizabethwfranklin@gmail.com">
          <span class="email"><i className="fa fa-envelope"></i></span>
          <span>elizabethwfranklin@gmail.com</span>
        </a>
      </li>
      <li>
        <h3>Social</h3>
        <ul className="icons">
          {/* <li>
            <a href="/#" className="fa-twitter">
              <span className="label">Twitter</span>
            </a>
          </li> */}
          <li>
            <a href="https://github.com/redliz5808" className="fa-github socialIcon" target="_blank" rel="noreferrer">
              <span className="label">GitHub</span>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/elizabethwfranklin/" className="fa-linkedin socialIcon" target="_blank" rel="noreferrer">
              <span className="label">LinkedIn</span>
            </a>
          </li>
          <li>
            <a href="https://stackoverflow.com/users/13066267/redliz5808?tab=profile" className="fa-stack-overflow socialIcon" target="_blank" rel="noreferrer">
              <span className="label">Stack Overflow</span>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/elizabeth.w.franklin" className="fa-facebook socialIcon" target="_blank" rel="noreferrer">
              <span className="label">Facebook</span>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/redliz0116/" className="fa-instagram socialIcon" target="_blank" rel="noreferrer">
              <span className="label">Instagram</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </section>

export default ContactInformation;
