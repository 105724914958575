import React from 'react';

import pic1 from '../../images/pic01.jpg';
import pic2 from '../../images/pic02.jpg';
import pic3 from '../../images/pic03.jpg';
import pic5 from '../../images/pic05.png';

import Feature from './Feature';

const Features = () => {
  const FEATURES = [
    {
      href: 'https://a-thousand-words-ia23oi68u-redliz5808.vercel.app/',
      image: pic1,
      heading: 'A Thousand Words',
      description: 'This is a React application built using the Unsplash API, Redux, Styled-Components, infinite scrolling, React-Slick, and theming.',
      to: {
        href: 'https://a-thousand-words-ia23oi68u-redliz5808.vercel.app/',
        label: 'View the Live Demo',
      },
      code: {
        href: 'https://github.com/redliz5808/AThousandWords',
        label: 'View the Code',
      },
    },
    {
      href: 'https://b8qgr.csb.app/',
      image: pic2,
      heading: 'Weather App',
      description: 'This is a simple weather app I made during my work with Cristian Florea in Developer Pro.',
      to: {
        href: 'https://b8qgr.csb.app/',
        label: 'View the Live Demo',
      },
      code: {
        href: 'https://codesandbox.io/s/weather-app-for-dp-refactored-b8qgr',
        label: 'View the Code',
      },
    },
    {
      href: 'https://codepen.io/redliz5808/full/WNoRvaX',
      image: pic3,
      heading: 'Pomodoro To Do App',
      description: 'This is a Pomodoro Timer / To Do App I made during my work with Cristian Florea in Developer Pro.',
      to: {
        href: 'https://codepen.io/redliz5808/full/WNoRvaX',
        label: 'View the Live Demo',
      },
      code: {
        href: 'https://codepen.io/redliz5808/pen/WNoRvaX',
        label: 'View the Code',
      },
    },
    {
      href: 'https://github.com/redliz5808/',
      image: pic5,
      heading: 'Additional Projects and Code',
      description: 'View my additional projects and code on GitHub.',
      to: {
        href: 'https://github.com/redliz5808/',
        label: 'View my GitHub Profile',
      },
    },
  ];

  const [features] = React.useState(FEATURES);

  return (
    <section id="one" className="wrapper style2 spotlights">
       {features.map(feature => <Feature key={feature.heading} {...feature} code={feature.code} />)}
    </section>
  );
}

export default Features;
